import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'uiComponents/Button';
import { StyledProfile } from './styles';
import { mdiLogout } from '@mdi/js';
import { useAuth0 } from '@auth0/auth0-react';
import { PageTitle } from 'uiComponents/PageTitle';

export const Profile = () => {
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const token = getAccessTokenSilently();
  console.log(token)

  return (
    <>
    <PageTitle title="Perfil Usuario" />
    <StyledProfile>
      <div>
        <img src={user!.picture} alt={user!.name} />
        <h2>{user!.name}</h2>
        <p>{user!.email}</p>
        <nav>
          <Link to="/dashboard">Dashboard</Link>
        </nav>

        <Button
          size="middle"
          id="LogoutButton"
          iconLeft={mdiLogout}
          label="Logout"
          onClick={() => { logout({ returnTo: window.location.origin }) }}
        />
      </div>
    </StyledProfile>
    </>
  );
};
