export type Booking = {
  id: number,
  name: string,
  timestamp: Date,
  created_at: Date,
  email: string,
}

export enum BookingActions {
  RESERVE_ADMIN,
  RESERVE,
  REMOVE,
}

export type PadelUser = {
  user_id: string,
  email: string,
  name: string,
  created_at: Date,
}
