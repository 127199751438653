import React from 'react';

import SVG, { IImageProps } from '../../SVG/SVG';

const MiniLogo = ({ className, id, fillType }: IImageProps) => (
  <SVG
    className={className}
    id={id}
    viewBox="0 0 33 46"
    fillType={fillType}
    icon="M17.314.657L16.5 0l-.814.657C15.045 1.174 0 13.496 0 29.087 0 38.413 7.402 46 16.5 46 25.598 46 33 38.413 33 29.087 33 13.496 17.955 1.174 17.314.657zm.503 42.578v-5.5l9.39-8.747a1.373 1.373 0 00.09-1.906 1.295 1.295 0 00-1.86-.092l-7.62 7.097v-9.07l5.897-5.491a1.374 1.374 0 00.09-1.907 1.296 1.296 0 00-1.861-.091l-4.126 3.842V8.302c0-.746-.59-1.35-1.317-1.35-.728 0-1.317.604-1.317 1.35v13.069l-4.126-3.843a1.296 1.296 0 00-1.86.092 1.374 1.374 0 00.09 1.906l5.896 5.491v9.07l-7.62-7.097c-.538-.5-1.37-.46-1.86.092a1.373 1.373 0 00.09 1.906l9.39 8.746v5.502c-7.03-.683-12.55-6.767-12.55-14.149 0-12.29 10.78-22.837 13.867-25.603 3.086 2.766 13.867 13.314 13.867 25.603 0 7.382-5.52 13.466-12.55 14.148z"
  />
);

export default React.memo(MiniLogo);
