import React, { CSSProperties } from 'react';

import { getSVGFillColor, SVGFillType } from './svgFillColor';

export interface IImageProps {
  readonly className?: string;
  readonly id?: string;
  readonly style?: CSSProperties;
  readonly fillType?: SVGFillType;
  readonly onClick?: any;
}

export interface ISVGProps extends IImageProps {
  readonly viewBox?: string;
  readonly icon: string;
  readonly height?: string | number;
  readonly width?: string | number;
}

const SVG = ({
  className,
  id,
  icon = '',
  viewBox = '0 0 24 24',
  style,
  height,
  width,
  fillType,
  onClick = undefined,
}: ISVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={className}
      id={id}
      style={{ fill: getSVGFillColor(fillType), ...style }}
      height={height}
      onClick={onClick}
      width={width}>
      <path d={icon} />
    </svg>
  );
};

export default React.memo(SVG);
