import { Dropdown as AntDropdown, Menu } from 'antd';
import React, { Fragment, ReactNode } from 'react';
import { colors, spacing, typography } from 'common/theme/styles';

import { Button } from 'uiComponents/Button';
import { Icon } from 'uiComponents/Icon';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import styled from 'styled-components';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

export type DropdownOption = {
  color?: string;
  label: string | ReactNode;
  href?: string;
  iconLeft?: string;
  clearPadding?: boolean;
  disabled?: boolean;
  component?: ReactNode;
  onClick?: (data: any) => void;
};

export interface IDropdown {
  label?: ReactNode;
  options: DropdownOption[];
  data?: any;
  iconLeft?: string;
  iconRight?: string;
  size?: 'small' | 'large';
  color?: string;
  primary?: boolean;
  placement?:
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight';
  expandedMobile?: boolean;
}

const StyledMenuItem = styled(Menu.Item)`
  font-size: ${typography.sizeF2};
  padding: 10px ${spacing.tightLooser};

  &.clearPadding {
    padding: 0;
  }

  .disabled {
    color: ${colors.lightGrey};
  }

  .ant-dropdown-menu-title-content {
    > a {
      display: flex;
      align-items: center;
      color: ${colors.textGrey};

      > svg {
        width: 20px;
        height: 20px;
        margin-right: ${spacing.small};
      }
    }

    > button {
      width: 100%;
      justify-content: start !important;
      padding-left: ${spacing.tightLooser};
    }
  }
`;

const StyledDropdownExpanded = styled.div`
  min-height: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  > * {
    &:not(:last-child) {
      margin-right: ${spacing.tight};
    }
  }

  a {
    display: inline-flex;
    min-height: ${spacing.regularLooser};
    align-items: center;
  }
`;

const Dropdown = ({
  label,
  options,
  data,
  iconLeft,
  iconRight,
  size,
  color,
  primary = false,
  placement = 'bottomRight',
  expandedMobile,
}: IDropdown) => {
  const { md } = useBreakpoint();

  function renderOption({
    href,
    iconLeft: optionIconLeft,
    label,
    color,
    disabled,
    component,
    onClick,
  }: DropdownOption) {
    if (component) {
      return component;
    }
    if (disabled) {
      return label;
    }
    return (
      <Link
        to={href || '#'}
        color={color}
        onClick={(_) => onClick && onClick(data)}>
        {optionIconLeft && <Icon color={color} icon={optionIconLeft} />}
        {label}
      </Link>
    );
  }

  function getMenu() {
    return (
      <Menu>
        {options.map((option, idx) => (
          <StyledMenuItem
            key={idx}
            className={clsx({ clearPadding: option.clearPadding })}
            disabled={option.disabled}>
            {renderOption(option)}
          </StyledMenuItem>
        ))}
      </Menu>
    );
  }

  if (expandedMobile && md === false) {
    return (
      <StyledDropdownExpanded>
        {options.map((option, idx) => (
          <Fragment key={idx}>{renderOption(option)}</Fragment>
        ))}
      </StyledDropdownExpanded>
    );
  }

  return (
    <AntDropdown overlay={getMenu()} trigger={['click']} placement={placement}>
      <Button
        type={primary ? 'primary' : 'link'}
        iconLeft={iconLeft}
        iconRight={iconRight}
        size={size}
        style={{ color: primary ? undefined : color || colors.grey }}
        label={label as string}
      />
    </AntDropdown>
  );
};

export default React.memo(Dropdown);
