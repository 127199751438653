import { StyledTopBarAvatar, StyledTopBarUserName } from '../styles';

import { ACTA_APPLICATION_USER } from 'constants/stateKeys';
import Acta from 'acta';
import React from 'react';

interface ITopBarUser {
  avatar?: string,
}

export class TopBarUser extends React.Component<ITopBarUser> {
  public state = { user: undefined };

  public componentDidMount(): void {
  Acta.subscribeState(
    ACTA_APPLICATION_USER,
    (user) => this.setState({ user }),
    this,
  );
  }

  public componentWillUnmount(): void {
    Acta.unsubscribeState(ACTA_APPLICATION_USER, this);
  }

  public render(): JSX.Element {
    const { avatar } = this.props;

    return (
      <StyledTopBarUserName>
        {
          <StyledTopBarAvatar src={avatar}>
            {avatar
              ? ''
              : "testing"}
          </StyledTopBarAvatar>
        }
      </StyledTopBarUserName>
    );
  }
}
