import { colors, spacing, startWith, typography } from 'common/theme/styles';

import { Avatar } from 'uiComponents/Avatar';
import styled from 'styled-components';

const TEST_ID = 'topBar';

export const StyledTopBar = styled.div.attrs({
  'data-test-id': TEST_ID,
})`
  height: ${spacing.loose};
  display: none;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding-right: 10px;
  z-index: 9;
  position: relative;

  ${startWith('md')} {
    display: flex;
    padding-right: ${spacing.tightLooser};
  }

  > a,
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    > svg {
      display: block;
    }

    &:hover {
      > svg {
        fill: ${colors.main} !important;
      }
    }

    .dropdown-content {
      margin-top: 65px;
    }
  }
`;

export const StyledTopBarUserName = styled.span`
  font-size: ${typography.sizeF1};
  font-weight: ${typography.weightLightMedium};
  margin: 0 -8px 0 0;
  color: ${colors.textGrey};
`;

export const StyledTopBarAvatar = styled(Avatar)`
  background-color: ${colors.secondary};
  margin-right: ${spacing.small};
`;
