import { colors } from 'common/theme/styles';
import styled from 'styled-components';

export const StyledProfile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${colors.white};
  width: 100vw;
  height: 100vh;

  > img,
  > svg {
    display: block;
    width: 200px;
    margin-bottom: 20px;
  }

  .loader {
    height: 96px;
  }
`;
