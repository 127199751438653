import React, { useEffect } from 'react';

import { Button } from 'uiComponents/Button';
import Loader from 'uiComponents/Loader';
import { Logo } from 'uiComponents/Logo';
import Spinner from 'uiComponents/SVG/Spinner';
import { StyledLogin } from './styles';
import { colors } from 'common/theme/defaults';
import { mdiLogin } from '@mdi/js';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router';
import padelLogo from 'padel-logo-2.png';

interface ILogin {
  loading?: boolean;
}

export const Login = ({ loading }: ILogin) => {
  const { isLoading, loginWithRedirect, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  if (isLoading){
    return <Loader />
  }

  return (
    <StyledLogin>
      <Logo withLabel src={padelLogo} />

      {loading ? (
        <div className="loader">
          <Spinner color={colors.white} />
        </div>
      ) : (
        <>
          <Button
            size="large"
            id="LoginButton"
            iconLeft={mdiLogin}
            label="Acceder"
            onClick={() => {
              loginWithRedirect({
                appState: {
                  targetUrl: window.location.origin + window.location.pathname,
                },
                redirectUri: `${process.env.REACT_APP_APP_URL}/callback`,
                ui_locales: 'es',
              });
            }}
          />

          <Button
            type="link"
            size="large"
            id="SignUpButton"
            style={{ color: colors.white }}
            label="Nuevo usuario"
            onClick={() => {
              loginWithRedirect({
                appState: {
                  targetUrl: window.location.pathname + window.location.search,
                },
                screen_hint: 'signup',
                language: "es",
              });
            }}
          />
        </>
      )}
    </StyledLogin>
  );
};
