import React, { useState, useEffect, useCallback } from 'react';
import { Booking } from 'routes/ReserveCourt/types'
import { useAuth0, User } from '@auth0/auth0-react'
import { BookingHourActions } from './BookingHourActions'
import jwtDecode, { JwtPayload } from "jwt-decode";
import { BookingActions } from 'routes/ReserveCourt/types';
import './styles.css';

interface IBookingHour {
  hour: Date,
  bookings: Booking[],
  callback: Function,
}

interface IPayload extends JwtPayload {
  scope?: string;
}

export interface IUser extends User {
  scope?: string;
}


const isAdmin = (
  user: IUser
): boolean => {
  if (user.scope!.includes("admin:gym") ||
    user.scope!.includes("admin:all")) {
    return true
  }

  return false
}

export const getActions = (
  user: IUser,
  hour: Date,
  bookings: Booking[],
): BookingActions[] => {
  var actions: BookingActions[] = []
  var now = new Date()
  var maxDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 23, 59, 59)
  var admin = isAdmin(user)
  var booking = getBooking(hour, bookings)
  var noBookings = true
  // Check bookings of user
  for (let i of bookings) {
    if (i.timestamp.getDate() === hour.getDate() &&
      i.email === user.email)
      noBookings = false
  }


  // If it is an hour in the past, no actions can be done
  if (hour.getTime() < now.getTime()) {
    return []
  }
  // If the hour is booked and user is an admin or the user who did the booking
  // let them remove the booking
  if (booking) {
    if (admin || user.email === booking.email) {
      actions.push(BookingActions.REMOVE)
    }
  } else {
    // If the hour is not booked and user is an admin add action to reserve for admins
    if (admin) {
      actions.push(BookingActions.RESERVE_ADMIN)
    } else {
      // If the user is not admin and the hour is within next day and has no other bookings
      // Let them reserve
      if (hour.getTime() < maxDate.getTime() && noBookings) {
        actions.push(BookingActions.RESERVE)
      }
    }
  }

  return actions
}

// Get the booking for a given day-hour
export const getBooking = (
  hour: Date,
  bookings: Booking[],
): Booking | null => {
  var booking: Booking
  for (booking of bookings) {
    if (hour.getTime() === new Date(booking['timestamp']).getTime()) {
      return booking
    }

  }
  return null
}

export const BookingHour = ({
  hour,
  bookings,
  callback,
}: IBookingHour): JSX.Element => {
  const { isLoading, getAccessTokenSilently, user } = useAuth0()
  const [theUser, setClaims] = useState<IUser>({ scope: "" })
  const booking = getBooking(hour, bookings)
  const getClaims = useCallback(async () => {
    const token = await getAccessTokenSilently()
    const decoded = jwtDecode<IPayload>(token)
    var newUser: IUser = user!
    newUser.scope = decoded.scope
    setClaims(newUser)
  }, [getAccessTokenSilently, setClaims, user])

  useEffect(() => {
    getClaims()
  }, [getClaims])

  if (isLoading || !theUser) {
    // TODO: Use <Loader />
    return <div>Loading...</div>
  }

  const actions = getActions(theUser, hour, bookings)
  var bookingClass: string
  if (booking) {
    if (booking.email === theUser.email)
      bookingClass = "self"
    else
      bookingClass = "other"
  } else {
    bookingClass = "free"
  }

  return (
    <div className={bookingClass + " booking-hour"}>
      <div className="booking-name">{booking && booking.name}</div>
      <BookingHourActions booking={booking} hour={hour} user={theUser}
                          actions={actions} callback={callback} />
    </div>
  )
}
