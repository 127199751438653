export const slugify = (name: string): string => {
  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return (
    name
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      // eslint-disable-next-line
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      // eslint-disable-next-line
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '')
  ); // Trim - from end of text
};

export const formatPathName = (path: string) => {
  return path === '' ? 'Home' : path.replaceAll('-', ' ');
};

export const parseQuery = (query = ''): any => {
  const search = query.substring(1);
  try {
    return JSON.parse(
      '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
      function (key, value) {
        return key === '' ? value : decodeURIComponent(value);
      },
    );
  } catch (e) {
    return {};
  }
};

export const generateCode = () => {
  const alphaNum =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const idLen = 32;
  let id = '';
  for (let i = 0; i < idLen; i += 1) {
    if (i % 8 === 0 && i !== 0) {
      id += '-';
    }
    id += alphaNum.charAt(Math.floor(Math.random() * alphaNum.length));
  }
  return id;
};
