import React, { useState, useCallback, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from 'uiComponents/Loader';
import { PageTitle } from 'uiComponents/PageTitle';
import { Calendar } from 'uiComponents/Calendar';
import { getWeek, getMonthStr } from 'utils/commonUtils'
import { Booking } from './types'
import { Button } from 'uiComponents/Button';
import './style.css'
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';

export const ReserveCourt = () => {
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const [bookings, setBookings] = useState<Booking[]>([])
  const [week, setWeek] = useState<Date[]>([])
  const [monthStr, setMonthStr] = useState("")
  const [count, setCount] = useState(0)
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const weekNumberDays = isMobile ? 3 : 7

  const getBookings = useCallback(async () => {
    const token = await getAccessTokenSilently()
    var headers = { 'Authorization': 'Bearer ' + token }
    const newWeek = getWeek(count, weekNumberDays)
    if (newWeek) {
      var startDate = new Date([
        newWeek[0].getFullYear(),
        newWeek[0].getMonth() + 1,
        newWeek[0].getDate(),
      ].join("-"))
      var endDate = new Date([
        newWeek[weekNumberDays - 1].getFullYear(),
        newWeek[weekNumberDays - 1].getMonth() + 1,
        newWeek[weekNumberDays - 1].getDate(),
      ].join("-"))
      setMonthStr(getMonthStr(newWeek))

      // Get bookings
      axios.get<Booking[]>(`${process.env.REACT_APP_PADEL_API_URL}/bookings`, {
        params: {
          startDate: startDate.getTime(),
          endDate: endDate.getTime()
        },
        headers
      }).then(response => {
        const { data } = response
        var bookings: Booking[] = []

        for (let i of data) {
          let b = {
            id: i.id,
            name: i.name,
            timestamp: new Date(i.timestamp),
            created_at: new Date(i.created_at),
            email: i.email
          }
          bookings.push(b)
        }
        setBookings(bookings)
      })
    }
  }, [getAccessTokenSilently, count, weekNumberDays]);

  useEffect(() => {
    setWeek(getWeek(count, weekNumberDays))
    getBookings()
    const interval = setInterval(() => getBookings(), 10000)
    return () => clearInterval(interval)
  }, [getBookings, count, weekNumberDays])

  if (isLoading) {
    return <Loader />
  }

  if (!bookings) {
    return (<div></div>)
  }

  return (
    <>
      <PageTitle title="Reserva de Pista" />
      <div className="month-wrapper row align-items-center justify-content-center mt-2">
        <div className="col-auto float-start">
          <Button size="small" onClick={() => {
            setWeek(getWeek(count, weekNumberDays)); setCount(count - 1)
          }}>
            &lt;
          </Button>
        </div>
        <div className="col-auto fw-bold fs-5">{monthStr}</div>
        <div className="col-auto float-end">
          <Button size="small" onClick={() => {
            setWeek(getWeek(count, weekNumberDays)); setCount(count + 1)
          }}>
            &gt;
        </Button>
        </div>
        <div style={{ clear: "left" }}></div>
      </div>
      <Calendar week={week} bookings={bookings} callback={getBookings} />
    </>
  );
};
