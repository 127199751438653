interface IDayHeader {
  date: Date,
}

const isToday = (date: Date) => {
  var today = new Date()
  if (today.getFullYear() === date.getFullYear() &&
      today.getMonth() === date.getMonth() &&
      today.getDate() === date.getDate()) {
    return true
  }

  return false
}

export const DayHeader = ({
  date,
}: IDayHeader): JSX.Element => {
  const active = isToday(date)
  const moment = require('moment')
  moment.updateLocale('es', {
    weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
    weekdaysShort: 'Dom_Lun_Mar_Mie_Jue_Vie_Sab'.split('_')
  })
  //moment.locale('es')

  return (
    <div className="day-header">
      <div className="day-name">{moment(date).format('ddd')}</div>
      <div className={`${active ? 'active' : ''} day-number`}>{date.getDate()}</div>
    </div>
  )
}
