import {
  mdiMinusCircle, mdiPlusCircle
} from '@mdi/js';
import React, { useState, useCallback } from 'react';
import { BookingActions } from 'routes/ReserveCourt/types';
import { BasicModal } from 'uiComponents/BasicModal';
import { Icon } from 'uiComponents/Icon';
import { IUser } from './BookingHour';
import Loader from 'uiComponents/Loader';
import { Booking } from 'routes/ReserveCourt/types'
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { PadelUser } from 'routes/ReserveCourt/types';
import { AutoComplete, AutoCompleteData } from "@react-md/autocomplete";
import { Divider } from "@react-md/divider";
import { TextField } from '@react-md/form'


interface IBookingHourActions {
  booking: Booking | null,
    hour: Date,
      user: IUser,
        actions: BookingActions[],
          callback: Function,
}

export const BookingHourActions = ({
  booking,
  hour,
  user,
  actions,
  callback,
}: IBookingHourActions): JSX.Element => {
  var moment = require('moment')
  const [reserveModalVisible, setReserveModalVisible] = useState(false)
  const [reserveAdminModalVisible, setReserveAdminModalVisible] = useState(false)
  const [removeModalVisible, setRemoveModalVisible] = useState(false)
  const [isModalLoading, setModalLoading] = useState(false)
  const [registeredUsers, setRegisteredUsers] = useState<AutoCompleteData[]>([])
  const { getAccessTokenSilently } = useAuth0();
  const modalRef = React.createRef<HTMLDivElement>()
  const registeredUserRef = React.createRef<HTMLInputElement>()
  const otherUserRef = React.createRef<HTMLInputElement>()

  const getUsers = useCallback(async () => {
    const token = await getAccessTokenSilently()
    var headers = {
      'Authorization': 'Bearer ' + token
    }
    axios.get<PadelUser[]>(`${process.env.REACT_APP_PADEL_API_URL}/users`, {
      headers
    }).then(response => {
      const { data } = response
      setRegisteredUsers(data.map<AutoCompleteData>(
        ({ name, email }) => ({
          label: (
            <>
              {`${name} `}({`${email}`})
            </>
          ),
          value: name + " " + email,
        })
      ))
    })
  }, [getAccessTokenSilently])

  const reserveAdmin = () => {
    var registeredUser = registeredUserRef.current
    var otherUser = otherUserRef.current
    var name = ""
    var email = ""
    var timestamp = moment(hour).format('YYYY-MM-DDTHH:mm:ss.SSSSSZ')

    const addBooking = async ({
      name,
      email,
      timestamp,
    }: {
      name: string,
      email: string,
      timestamp: Date
    }) => {
      const token = await getAccessTokenSilently()
      var headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
      var body = JSON.stringify({
        timestamp: timestamp,
        name: name,
        email: email
      })
      axios.post(`${process.env.REACT_APP_PADEL_API_URL}/bookings`, body, {
        headers: headers
      }).then(() => {
        callback()
        setModalLoading(false)
        setReserveAdminModalVisible(false)
      }).catch(err => {
        if (err.response) {
          console.log(err.response)
        } else {
          console.log(err)
        }
      })
    }

    // Checks
    if (registeredUser && registeredUser.value !== "") {
      let value = registeredUser.value.split(" ")
      name = value.slice(0, -1).join(" ")
      email = value.slice(-1)[0]
    } else {
      if (!otherUser ||otherUser.value === ""){
        console.log("Error: ningun usuario seleccionado")
      } else {
        name = otherUser.value
      }
    }

    addBooking({name, email, timestamp})
  }

  const reserve = () => {
    const addBooking = async () => {
      const token = await getAccessTokenSilently()
      var headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
      var body = JSON.stringify({
        timestamp: moment(hour).format('YYYY-MM-DDTHH:mm:ss.SSSSSZ'),
        name: user.name,
        email: user.email
      })
      axios.post(`${process.env.REACT_APP_PADEL_API_URL}/bookings`, body, {
        headers: headers
      }).then(() => {
        callback()
        setModalLoading(false)
        setReserveModalVisible(false)
      }).catch(err => {
        if (err.response) {
          console.log(err.response)
        } else {
          console.log(err)
        }
      })
    }

    setModalLoading(true)
    addBooking()
    setReserveModalVisible(true)
    callback()
  }

  const remove = () => {
    if (booking) {
      const msgDiv = modalRef.current
      const delBooking = async () => {
        const token = await getAccessTokenSilently()
        var headers = { 'Authorization': 'Bearer ' + token }
        axios.delete(`${process.env.REACT_APP_PADEL_API_URL}/bookings/` + booking.id, {
          headers
        }).then(response => {
          console.log(response)
          callback()
          setModalLoading(false)
          setRemoveModalVisible(false)
        })
      }

      if (msgDiv) {
        msgDiv.innerHTML = "Eliminando reserva..."
      }
      setModalLoading(true)
      delBooking()
    } else {
      console.log("Booking object is null")
      setRemoveModalVisible(false)
    }
  }

  const showReserveAdminModal = () => {
    getUsers()
    setReserveAdminModalVisible(true)
  }

  var hourStr = moment(hour).format('HH:mm')
  return (
    <div className="hour-actions">
      {actions.includes(BookingActions.RESERVE_ADMIN) &&
        <>
          <BasicModal
            okText="Confirmar"
            cancelText="Cancelar"
            visible={reserveAdminModalVisible}
            onCancel={() => { setReserveAdminModalVisible(false) }}
            onOk={reserveAdmin}>
            <div>Reserva de pista para el dia {hour.getDate()} a las {hourStr}</div>
            <Divider />
            <AutoComplete
              id="registered-user"
              name="registered-user"
              label="Usuario registrado: "
              placeholder="Nombre..."
              filter="fuzzy"
              data={registeredUsers}
              ref={registeredUserRef}
            />
            <TextField
              id="other-user"
              type="text"
              label="Otros: "
              ref={otherUserRef}
            />
          </BasicModal>
          <Icon onClick={() => { showReserveAdminModal() }} width={18} icon={mdiPlusCircle} padLeft />
        </>
      }
      {actions.includes(BookingActions.RESERVE) &&
        <>
          <BasicModal
            okText="Confirmar"
            cancelText="Cancelar"
            visible={reserveModalVisible}
            onCancel={() => { setReserveModalVisible(false) }}
            onOk={reserve}>
            <div>Desea reservar la pista para el dia {hour.getDate()} a las {hourStr}?</div>
          </BasicModal>
          <Icon onClick={() => { setReserveModalVisible(true) }} width={18} icon={mdiPlusCircle} padLeft />
        </>
      }
      {actions.includes(BookingActions.REMOVE) &&
        <>
          <BasicModal
            okText="Confirmar"
            cancelText="Cancelar"
            visible={removeModalVisible}
            onCancel={() => { setRemoveModalVisible(false) }}
            onOk={() => { remove() }}>
            <div ref={modalRef}>Desea eliminar la reserva del dia {hour.getDate()} a las {hourStr}?</div>
            {isModalLoading && <Loader />}
          </BasicModal>
          <Icon onClick={() => { setRemoveModalVisible(true) }} width={18} icon={mdiMinusCircle} padLeft />
        </>
      }
    </div>
  )
}
