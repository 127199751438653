module.exports = {
  colors: {
    main: '#1a5afe',
    mainLight: '#a8b2ff',
    mainLighter: '#e6e9ff',
    mainDark: '#012c8e',
    mainDarker: '#001146',
    mainText: '#191919',
    secondary: '#20AA81',
    white: '#ffffff',
    black: '#000000',
    sidebarBackground: '#151B28',
    sidebarBackgroundHover: '#090b12',
    sidebarText: '#fff',
    sidebarTextHover: '#3be3db',

    notificationBackground: '#000a28',

    gradient1: 'linear-gradient(135deg, #2c56dd 17.68%, #3be3db 86.46%)',
    gradient2: 'linear-gradient(135deg, #1a5afe 26.52%, #f82495 91.16%)',
    gradient3:
      'linear-gradient(90deg, #1a5afe 12.5%, #3be3db 74.99%, #c0f188 100%)',
    gradient4:
      'linear-gradient(90deg, rgba(248, 36, 149, 0) 37.5%, #fbd448 100%)',

    accent1: '#d73184',
    accent1Light: '#fab6d7',
    accent2: '#ae4bcb',
    accent2Light: '#ebcdf7',
    accent3: '#6367eb',
    accent3Light: '#d4d6ff',
    accent4: '#2f837e',
    accent4Light: '#a2dedb',
    accent5: '#677e4b',
    accent5Light: '#cfe6aa',

    success: '#69bfa0',
    successLight: '#b8e5d3',
    successLighter: '#dff2ea',
    successDark: '#008055',
    successDarker: '#006647',
    successText: '#191919',
    successStatus: '#629749',

    warning: '#e8a126',
    warningLight: '#ffdea6',
    warningLighter: '#fff1d9',
    warningDark: '#cc8100',
    warningDarker: '#994d00',
    warningText: '#191919',

    error: '#e58989',
    errorLight: '#f2cece',
    errorLighter: '#faebeb',
    errorDark: '#b20000',
    errorDarker: '#b71c1c',
    errorText: '#191919',
    errorMessage: '#ff4d4f',

    grey: '#666666',
    darkerGrey: '#777C8C',
    darkGrey: '#333333',
    lightGrey: '#989CA6',
    lightestGrey: '#f6f6f6',
    slightlyGrey: '#C2C4CC',
    textGrey: '#475166',

    border: '#dfe0e6',

    neutral: '#f8f8f9',
  },
  typography: {
    weightLight: 300,
    weightNormal: 400,
    weightLightMedium: 500,
    weightMedium: 600,
    weightBold: 650,
    sizeF7: '64px',
    sizeF6: '48px',
    sizeF5: '32px',
    sizeF4: '24px',
    sizeF3: '20px',
    sizeF2: '16px',
    sizeF1: '14px',
    sizeF0: '12px',
  },
  fontFamilyDefault: "'Inter', 'Helvetica Neue', sans-serif",
  fontFamilyNarrow: "'Roboto', 'Helvetica Neue', sans-serif",
  spacing: {
    none: 0,
    xs: '4px',
    small: '8px',
    smallLooser: '12px',
    tight: '16px',
    tightLooser: '24px',
    regular: '32px',
    regularLooser: '40px',
    finger: '48px',
    loose: '64px',
    large: '128px',
    xl: '256px',
    xxl: '512px',
  },
  borderRadius: '4px',
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
  },
};
