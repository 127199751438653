import React, { CSSProperties } from 'react';

import FullLogo from 'uiComponents/SVG/FullLogo';
import MiniLogo from 'uiComponents/SVG/MiniLogo';
import { SVGFillType } from 'uiComponents/SVG';

interface ILogo {
  readonly className?: string;
  readonly id?: string;
  readonly style?: CSSProperties;
  readonly withLabel?: boolean;
  readonly src?: string;
  readonly fillType?: SVGFillType;
}

const Logo = ({ withLabel, className, id, style, src, fillType }: ILogo) => {
  if (src) {
    return (
      <img className={className} id={id} style={style} alt="logo" src={src} />
    );
  }

  if (withLabel) {
    return (
      <FullLogo
        className={className}
        id={id}
        style={style}
        fillType={fillType}
      />
    );
  }

  return (
    <MiniLogo className={className} id={id} style={style} fillType={fillType} />
  );
};

export default Logo;
