import { ReactElement, useEffect, useState } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import PageLoader from 'uiComponents/PageLoader';

export const AuthProvider = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  const [credentials, setCredentials] = useState<{
    domain: string;
    clientId: string;
    audience: string;
  }>();

  useEffect(() => {
    const loadConfig = async () => {
      setCredentials({
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
        domain: process.env.REACT_APP_AUTH0_DOMAIN!,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
      });
    }
    loadConfig();
  }, []);

  const onRedirectCallback = (appState: any) => {
    // Removes query params and does a reload
    //window.location.href = window.location.origin + window.location.pathname;
  };

  if (!credentials) {
    return <PageLoader />;
  }

  const { domain, clientId, audience } = credentials;

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      audience={audience}>
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
