import { Helmet } from 'react-helmet';
import React from 'react';

const BrowserTitle = ({ title }: { title: string }) => (
  <Helmet>
    <title>{title} - Padel Burguillos</title>
  </Helmet>
);

export default BrowserTitle;
