import { StyledCalendar } from './styles';
import { Booking } from 'routes/ReserveCourt/types'
import { BookingHour } from './components/BookingHour'
import { DayHeader } from './components/DayHeader'

interface ICalendarProps {
  week: Date[],
  bookings: Booking[],
  callback: Function,
}


export const hourInDay = (
  hourday: Date,
  day: Date,
): boolean => {
  return hourday.getDate() === day.getDate()
}

export const Calendar = ({
  week,
  bookings,
  callback,
}: ICalendarProps): JSX.Element => {
  const moment = require('moment')
  const BookingHours = [
    [9, 0],
    [10, 30],
    [12, 0],
    [17, 0],
    [18, 30],
    [20, 0],
    [21, 30],
  ]
  const hours: Date[] = []

  for (let i = 0; i < week.length; i++) {
    for (let j = 0; j < BookingHours.length; j++) {
      var d = new Date(week[i].toUTCString())
      d.setHours(BookingHours[j][0])
      d.setMinutes(BookingHours[j][1])
      d.setSeconds(0)
      d.setMilliseconds(0)
      hours.push(d)
    }
  }
  const hoursheader = hours.slice(0,7)

  return (
    <StyledCalendar>
      {/* Headers */}
      <div className="separator clearfix"></div>
      <div className="row p-4">
        {/* Hours column */}
        <div className="hours col-2 col-md float-start p-0">
          <div className="hour-header"></div>
          {hoursheader.map(hour =>
            <div className="hour d-flex align-items-center justify-content-center" key={moment(hour).format('HH:mm')}>
              {moment(hour).format('HH:mm')}
            </div>)}
        </div>

        {/* Bookings days */}
        {week.map(day =>
          <div className="day col-3 col-md float-start p-0" key={day.getDate() + "-book"}>
            <DayHeader date={day} />
            {hours.filter(hourday => hourInDay(hourday, day))
              .map(hour =>
                <BookingHour key={day.getDate() + "-" + moment(hour).format('HH:mm')}
                  hour={hour}
                  bookings={bookings}
                  callback={callback}
                />
              )}
          </div>)}
      </div>
    </StyledCalendar>
  );
};
