import React, { CSSProperties } from 'react';
import SVG, { ISVGProps } from '../SVG/SVG';

import { spacing } from 'common/theme/defaults';

export interface IIconProps extends ISVGProps {
  readonly color?: string;
  readonly padLeft?: boolean;
  readonly padRight?: boolean;
}

const Icon = ({
  className,
  height,
  icon = '',
  color,
  style,
  width = 24,
  viewBox,
  padLeft,
  padRight,
  onClick,
}: IIconProps) => {
  const styles: CSSProperties = {
    fill: color || 'currentColor',
    stroke: 'none',
    cursor: onClick === undefined ? 'auto' : 'pointer',
    transition: 'fill .2s ease-out, transform .2s ease-out',
    ...style,
  };

  return (
    <SVG
      className={className}
      style={{
        marginLeft: padLeft ? spacing.xs : undefined,
        marginRight: padRight ? spacing.xs : undefined,
        ...styles,
      }}
      height={height || width}
      width={width}
      viewBox={viewBox || '0 0 24 24'}
      icon={icon}
      onClick={onClick}
    />
  );
};

export default React.memo(Icon);
