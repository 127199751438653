import { colors } from 'common/theme/styles';

export type SVGFillType = 'default' | 'hover' | 'brand' | 'dark';

export const getSVGFillColor = (svgFillType?: SVGFillType) => {
  switch (svgFillType) {
    case 'hover':
      return colors.sidebarTextHover;
    case 'brand':
      return colors.secondary;
    case 'dark':
      return colors.sidebarBackground;
    default:
      return colors.white;
  }
};
