import {
  colors,
  spacing,
  typography
} from 'common/theme/styles';

import { Avatar } from 'uiComponents/Avatar';
import styled from 'styled-components';

const TEST_ID = 'calendar';

export const StyledCalendar = styled.div.attrs({
  'data-test-id': TEST_ID,
})`
  min-height: 600px;
  width: 100%;

  .separator {
    width: 100%;
    border-bottom: 2px solid #dfe0e6;
  }
  .day {
    text-align: center;
    margin-left: 2px;
  }
  .booking-hour {
    border: 1px solid gray;
  }
  .day > div, .hours>div {
    min-height: 70px;
    height: 70px;
  }
  .hour {
    border-bottom: 1px solid gray;
  }

  .clearfix::after {
    clear: both;
  }
  .float {
    float: left;
  }

  .hour-actions {
    position: absolute;
    right: 0;
    bottom: 0;
    padding-right: 2px;
  }
`;

export const StyledTopBarUserName = styled.span`
    font-size: ${typography.sizeF1};
    font-weight: ${typography.weightLightMedium};
    margin: 0 -8px 0 0;
    color: ${colors.textGrey};
`;

export const StyledTopBarAvatar = styled(Avatar)`
  background-color: ${colors.secondary};
  margin-right: ${spacing.small};
`;
