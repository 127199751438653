import { mdiAccountCircle, mdiLogout } from '@mdi/js';

import { Dropdown } from 'uiComponents/Dropdown';
import React from 'react';
import { StyledTopBar } from './styles';
import { TopBarUser } from './components/TopBarUser';
import { useAuth0 } from '@auth0/auth0-react';

export const TopBar = () => {
  const { user: auth0User } = useAuth0();
  const avatar =
    auth0User?.picture && auth0User.picture.indexOf('gravatar.com') === -1
      ? auth0User.picture
      : undefined;

  return (
    <StyledTopBar>
      <Dropdown
        label={<TopBarUser avatar={avatar} />}
        options={[
          {
            label: "Perfil",
            iconLeft: mdiAccountCircle,
            href: "/profile",
          },
          {
            label: "Salir",
            iconLeft: mdiLogout,
            href: "/logout",
          },
        ]}
      />
    </StyledTopBar>
  );
};
