import React, { ReactElement, ReactNode } from 'react';
import { spacing, typography } from 'common/theme/styles';

import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { colors } from 'common/theme/defaults';
import styled from 'styled-components';

const StyledBasicModal = styled(Modal)<ModalProps>`
  .ant-modal-header {
    padding-top: ${spacing.tightLooser};
    padding-bottom: ${spacing.tightLooser};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-color: ${colors.border};
  }
  .ant-modal-title {
    font-size: ${typography.sizeF3};
  }
  .ant-modal-close {
    margin-top: 8px;
    margin-right: 4px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
  .ant-modal-content {
    border-radius: 4px;
  }
  .ant-modal-body {
    font-size: 1rem;
    padding-bottom: ${spacing.regular};

    p {
      line-height: 2rem;
      margin-bottom: 0;
    }
  }
  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px ${spacing.tightLooser};
    border-top-color: ${colors.border};
  }
`;

const BasicModal = ({
  children,
  ...props
}: ModalProps & { children: ReactNode }): ReactElement => (
  <StyledBasicModal {...props}>{children}</StyledBasicModal>
);

export default BasicModal;
