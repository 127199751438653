import { lessThan, startWith } from './breakpoints';

import { TypographyType } from '../../uiComponents/Typography/Typography';
import defaults from './defaults';
import styled from 'styled-components';

export { startWith, lessThan } from './breakpoints';

export const typography = defaults.typography;
export const colors = defaults.colors;
export const spacing = defaults.spacing;
export const fontFamilyNarrow = defaults.fontFamilyNarrow;
export const borderRadius = defaults.borderRadius;

export type AlertType = 'success' | 'info' | 'warning' | 'error';

export const typographyVariant = {
  h1: {
    fontSize: '2rem',
  },
  h2: {
    fontSize: '1.5rem',
  },
  h3: {
    fontSize: '1.25rem',
  },
  h4: {
    fontSize: '1.15rem',
  },
  h5: {
    fontSize: '1rem',
  },
  body1: {
    fontSize: '1rem',
  },
  body2: {
    fontSize: '0.875rem',
  },
} as { [key in TypographyType]?: { fontSize: string } };

export const narrowFormWidth = '500px';
export const narrowContentMaxWidth = '426px';
export const maxContentWidth = '1400px';
export const maxPageViewWidth = '1106px';
export const maxFullWidth = '1496px';

export const mainContentPadding = '78px';

export const boxShadowMedium = '0 4px 10px 0 rgba(0,0,0,0.05)';

export const responsiveContentPadding = `
padding: ${spacing.tight} ${spacing.tight};
  ${startWith('md')}{
    padding-left: ${spacing.regular};
    padding-right: ${spacing.regular};}
`;

export const brandTransition = (prop?: string) => `
transition: ${prop || 'all'} 0.5s ease;
`;

export const brandBoxShadow = `box-shadow: rgba(0, 0, 0, 0.05) 0 4px 10px;`;

export const tableHeader = `
  font-size: ${typography.sizeF1};
  font-weight: ${typography.weightMedium};
  border-bottom: 1px solid ${colors.border};
  padding: ${spacing.tightLooser} ${spacing.small} ${spacing.small};
  vertical-align: bottom;
`;

export const tableRow = `
  border-bottom: 1px solid ${colors.border};
  ${brandTransition('background')}

  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }
`;

export const tableCol = `
  color: ${colors.textGrey};
  vertical-align: middle;
  padding: ${spacing.small};
  height: ${spacing.finger};
`;

export const sectionPadding = `
    padding: ${spacing.tightLooser} ${spacing.tight};
    ${startWith('md')} {
      padding-left: ${spacing.tightLooser};
      padding-right: ${spacing.tightLooser};
      }
`;

export const boxSectionStyles = `
    border-radius: 4px;
    border: 1px solid ${colors.border};
    box-shadow: ${boxShadowMedium};
    background-color: ${colors.white};
    padding: ${spacing.tightLooser};
`;

export const StyledPageContainer = styled.div`
  margin: 0 auto;
  ${responsiveContentPadding}
  width: 100%;
  max-width: ${maxFullWidth};

  &.narrow {
    max-width: ${maxPageViewWidth};
  }
`;

export const StyledNarrowCenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${spacing.regular} auto;
  width: 100%;
  max-width: ${narrowFormWidth};
`;

export const StyledTableActionButtons = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${spacing.smallLooser};

  &:not(.alignStart) {
    justify-content: flex-end;
  }

  > button {
    padding-left: 0;
    padding-right: 0;
    margin-right: ${spacing.smallLooser};
  }
`;

export const StyledFormHeader = styled.h2`
  font-size: 1.5rem;
  margin-bottom: -${spacing.smallLooser};
`;

export const StyledComments = styled.span`
  white-space: pre-wrap;
`;

export const StyledSingleLine = styled.span`
  white-space: no-wrap;
`;

export const halfScreenFormField = (id: string, position: 1 | 2) =>
  `
  ${id} {
    .size-4 {
      width: 100%;
      ${startWith('lg')}{
        width: calc(50% - ${spacing.small});
        display: inline-flex;
        margin-${position === 1 ? 'right' : 'left'}: ${spacing.small};
      }
    }
  }
`;

export const StyledMetrics = styled.span`
  color: ${colors.darkerGrey};
  font-weight: ${typography.weightLightMedium};

  &.block {
    ${lessThan('md')} {
      margin-left: ${spacing.xs};
      font-weight: normal;
    }
    ${startWith('md')} {
      display: block;
    }
  }
`;

export const StyledColumn = styled.span`
  ${lessThan('md')} {
    font-weight: ${typography.weightLightMedium};
    display: inline-block;
  }
  > span {
    font-size: ${typography.sizeF0};
    font-weight: normal;
    display: block;
    padding-top: ${spacing.xs};

    &:not(.normal) {
      white-space: nowrap;
    }

    ${startWith('md')} {
      display: none;
    }
  }
`;
