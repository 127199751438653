import { Button as AntButton, ButtonProps as AntButtonProps } from 'antd';
import { colors, spacing, typography } from 'common/theme/styles';

import { Icon } from 'uiComponents/Icon';
import React from 'react';
import { ReactNode } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { useNavigate } from 'react-router';

export interface IButton extends AntButtonProps {
  label?: ReactNode;
  clearLeft?: boolean;
  clearRight?: boolean;
  iconLeft?: string;
  iconRight?: string;
  weightMedium?: boolean;
  success?: boolean;
}

const StyledButton = styled(AntButton)`
  &.ant-btn-link {
    font-weight: ${typography.weightLightMedium};
  }

  &.ant-btn-loading {
    display: flex;
    align-items: center;
  }

  &.ant-btn-sm {
    > span {
      > svg {
        width: ${spacing.tight};
      }
    }
  }

  &.clearLeft {
    padding-left: 0;
  }

  &.clearRight {
    padding-right: 0;
  }

  &.weightMedium {
    font-weight: ${typography.weightLightMedium};
  }

  &.success {
    background-color: ${colors.successDarker};
    border-color: ${colors.successDarker};
  }

  > span {
    display: inline-flex;
    align-items: center;
  }

  &[type='button'],
  &[type='submit'] {
    > span {
      height: 100%;
    }
  }

  // Should be fixed in later versions of AntD
  &.ant-btn-default.ant-btn-dangerous {
    &:not(:hover) {
      color: ${colors.errorDarker};
      border-color: ${colors.errorDarker};
    }
  }
`;

const Button = ({
  label,
  clearLeft,
  clearRight,
  iconLeft,
  iconRight,
  weightMedium,
  success,
  href,
  type = 'primary',
  size = 'small',
  onClick,
  children,
  ...other
}: IButton) => {
  const navigate = useNavigate();
  return (
    <StyledButton
      type={type}
      size={size}
      className={clsx({
        clearLeft,
        clearRight,
        weightMedium,
        success,
      })}
      href={href}
      {...other}
      onClick={
        href
          ? (e) => {
              e.preventDefault();
              navigate(href);
            }
          : onClick
      }>
      <span>
        {iconLeft && <Icon icon={iconLeft} padRight />}
        {label}
        {children}
        {iconRight && <Icon icon={iconRight} padLeft />}
      </span>
    </StyledButton>
  );
};

export default Button;
