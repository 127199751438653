import { Link, useLocation } from 'react-router-dom';
import { colors, typography } from 'common/theme/styles';
import { mdiArrowLeft, mdiMenuDown } from '@mdi/js';

import { Breadcrumb } from 'antd';
import BrowserTitle from './BrowserTitle';
import { Button } from 'uiComponents/Button';
import CSS from 'csstype';
import { Dropdown } from 'uiComponents/Dropdown';
import { Icon } from 'uiComponents/Icon';
import React from 'react';
import { StyledPageTitle } from './styles';
import { capitalize } from 'lodash';
import { formatPathName } from 'utils/query';
import styled from 'styled-components';

export interface NavigationAction {
  icon?: string;
  label: string;
  title?: string;
  color?: string;
  href?: string;
  disabled?: boolean;
  style?: CSS.Properties;
  isLoading?: boolean;
  secondary?: boolean;
  size?: 'small' | 'large';
  options?: TabNavigation[];
  action?: () => void;
}

export interface TabNavigation {
  label: string;
  href: string;
  isActive?: boolean;
}

interface IPageTitle {
  readonly backLink?:
    | string
    | {
        label: string;
        to: string;
      };
  readonly className?: string;
  readonly id?: string;
  readonly style?: CSS.Properties;
  readonly tabNavigation?: TabNavigation[];
  readonly tabActions?: NavigationAction[];
  readonly title: string;
  readonly subTitle?: string | React.ReactNode;
  readonly children?: React.ReactNode;
  readonly withBreadcrumbs?: boolean;
}

const StyledBreadcrumb = styled(Breadcrumb)`
  font-size: ${typography.sizeF1};
  text-transform: capitalize;
  line-height: 2;
`;

const PageTitle = ({
  backLink,
  className = '',
  id,
  style = {},
  tabNavigation = [],
  tabActions = [],
  title = 'YOU MUST SET A TITLE',
  subTitle,
  withBreadcrumbs = false,
  children,
}: IPageTitle) => {
  const { pathname } = useLocation();
  const pathnameParts = pathname.split('/');

  const renderTabNavigationAction = (
    tabNavigationAction: NavigationAction,
    idx: number,
  ) => {
    const { label, options } = tabNavigationAction;
    if (options) {
      return (
        <Dropdown
          key={`tabNavigationAction-${idx}`}
          label={label}
          options={options}
          primary
          size="large"
          color={colors.main}
          iconRight={mdiMenuDown}
        />
      );
    }

    const {
      icon,
      title,
      isLoading,
      disabled,
      size,
      style,
      secondary,
      href,
      action,
    } = tabNavigationAction;
    return (
      <Button
        key={`tabNavigationAction-${idx}`}
        type={secondary ? 'default' : undefined}
        iconLeft={icon}
        loading={isLoading}
        disabled={disabled}
        size={size || 'small'}
        style={style}
        href={href}
        label={label}
        title={title}
        onClick={action}
      />
    );
  };

  return (
    <>
      <BrowserTitle title={title} />
      <StyledPageTitle
        className={`pageTitle ${className} ${
          tabNavigation && tabNavigation.length > 0 ? 'hasTabNavigation' : ''
        }`}
        id={id}
        style={style}>
        <div>
          {backLink && typeof backLink === 'string' && (
            <Link
              to="#"
              className="backlink"
              onClick={() => window.history.back()}>
              <Icon icon={mdiArrowLeft} width={18} color={colors.main} />
              <span style={{ color: colors.main }}>{backLink}</span>
            </Link>
          )}
          {backLink && typeof backLink !== 'string' && (
            <Link to={backLink.to} className="backlink">
              <Icon icon={mdiArrowLeft} width={18} color={colors.main} />
              <span style={{ color: colors.main }}>{backLink.label}</span>
            </Link>
          )}
          {withBreadcrumbs && (
            <StyledBreadcrumb>
              {pathnameParts.map((path, index) => {
                return (
                  <Breadcrumb.Item key={path}>
                    <Link to={pathnameParts.slice(0, index + 1).join('/')}>
                      {capitalize(formatPathName(path))}
                    </Link>
                  </Breadcrumb.Item>
                );
              })}
            </StyledBreadcrumb>
          )}
          <span className="title">{title}</span>
          {subTitle && <span>{subTitle}</span>}
        </div>

        {children && children}

        {tabActions.length > 0 && (
          <div className="tabActions">
            {tabActions.map((tabNavigationAction, idx) =>
              renderTabNavigationAction(tabNavigationAction, idx),
            )}
          </div>
        )}

        {tabNavigation.length > 0 && (
          <div className="tabNavigation">
            <div>
              {tabNavigation.map((item) => (
                <Link
                  to={item.href}
                  key={item.href}
                  className={item.isActive ? 'active' : undefined}
                  style={{
                    color: colors.main,
                  }}>
                  {item.label}
                </Link>
              ))}
            </div>
          </div>
        )}
      </StyledPageTitle>
    </>
  );
};

export default PageTitle;
