import { Routes, Route, Navigate } from 'react-router-dom';
import { Login } from 'routes/Login';
import { Logout } from 'routes/Logout';
import { Callback } from 'routes/Callback';
import { Profile } from 'routes/Profile';
import { ReserveCourt } from 'routes/ReserveCourt'
import RequireAuth from 'router/RequireAuth'
import { Layout } from 'uiComponents/Layout';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/">
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="callback" element={<Callback />} />
        <Route element={<Layout />}>
          <Route index element={
            <RequireAuth redirectTo="/login">
              <ReserveCourt />
            </RequireAuth>}
          />
          <Route path="profile" element={
            <RequireAuth redirectTo="/login">
              <Profile />
            </RequireAuth>}
          />
        </Route>
      </Route>
      {/* TODO: Handle error page */}
      <Route path="*" element={<Navigate to={'/'} />} />
    </Routes>
  );
};

export default AppRoutes;
