import { breakpoints } from './defaults';

// sm: "576px",
// md: "768px",
// lg: "992px",
// xl: "1200px",
// xxl: "1600px"

export const startWith = (key: keyof typeof breakpoints | number) =>
  `@media screen and (min-width: ${breakpoints[key] || key}px)`;

export const lessThan = (key: keyof typeof breakpoints | number) =>
  `@media screen and (max-width: ${
    (breakpoints[key] || (key as number)) - 1
  }px)`;

export const between = (
  minKey: keyof typeof breakpoints,
  maxKey: keyof typeof breakpoints,
) =>
  `@media screen and (min-width: ${breakpoints[minKey]}px) and (max-width: ${
    breakpoints[maxKey] - 1
  }px)`;
