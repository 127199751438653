import {
  ACTA_APPLICATION_MENU_MINIFIED,
} from 'constants/stateKeys';

import React, { useState } from 'react';
import { Outlet, useLocation, Link } from 'react-router-dom';
import { Logo } from 'uiComponents/Logo';
import { Icon } from 'uiComponents/Icon';
import { TopBar } from 'uiComponents/TopBar';
import Acta from 'acta';
import { StyledLayout, StyledSidebarButton } from './styles';
import { colors } from 'common/theme/styles';
import {
  mdiSoccerField,
  mdiClose,
  mdiMenu,
  mdiBellRing,
  mdiChevronLeft,
  mdiChevronRight,
  mdiAccountCircle,
} from '@mdi/js';
import padelLogo from 'padel-logo-2.png';

interface IMenuItem {
  label: string;
  to: string;
  icon?: string;
  viewBox?: string;
}

const menuItems = (): Array<IMenuItem> => [
  {
    label: "Reserva pista",
    to: "/",
    icon: mdiSoccerField,
  },
  {
    label: "Perfil",
    to: "/profile",
    icon: mdiAccountCircle,
  }
]

const Aside = () => {
  const [isMenuMinified, setMinify] = useState(
    Boolean(Acta.getState(ACTA_APPLICATION_MENU_MINIFIED)),
  );
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const miniLogoStyle = { "height": 50, "width": 50 } as React.CSSProperties;

  return (
    <aside className={isMenuMinified ? 'minified' : ''}>
      <header>
        <button onClick={() => setOpen(!open)}>
          <Icon icon={open ? mdiClose : mdiMenu} color="#fff" />
        </button>
        <Logo withLabel src={padelLogo}
          className={`desktop ${isMenuMinified ? 'minified' : ''}`}
        />
        <Logo src={padelLogo} style={miniLogoStyle}
          className={`mobile ${isMenuMinified ? 'minified' : ''}`}
        />
        <button style={{ visibility: 'hidden' }}>
          <Icon icon={mdiBellRing} width={20} color={colors.mainLight} />
          <span>2</span>
        </button>
      </header>

      <menu className={`${open ? 'opened' : ''} ${isMenuMinified ? 'minified' : ''}`}>
        <StyledSidebarButton
          onClick={() => {
            Acta.setState(
              { [ACTA_APPLICATION_MENU_MINIFIED]: !isMenuMinified },
              'localStorage',
            );
            setMinify(!isMenuMinified);
          }}>
          <Icon
            icon={isMenuMinified ? mdiChevronRight : mdiChevronLeft}
            width={24}
            color="#333"
          />
        </StyledSidebarButton>
        {menuItems()
          .map((link) => {
            const isActive = pathname === link.to;
            return (
              <div key={link.to}>
                <Link
                  to={link.to}
                  onClick={() => setOpen(false)}
                  className={`${isMenuMinified ? 'minified' : ''}${isActive ? ' active' : ''
                    }`}>
                  {link.icon && (
                    <Icon
                      icon={link.icon}
                      width={24}
                      color={colors.sidebarText}
                      viewBox={link.viewBox}
                    />
                  )}
                  <span>{link.label}</span>
                </Link>
              </div>
            );
          })}
        <div className="spacer" />
        <hr />
      </menu>
    </aside>
  )
}

class Layout extends React.Component {
  public render() {
    return (
      <>
        <StyledLayout>
          <Aside />
          <main>
            <TopBar />
            <Outlet />
          </main>
        </StyledLayout>
      </>
    );
  }
};

export default Layout;
