import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import Loader from 'uiComponents/Loader';

interface IProps {
  children: JSX.Element;
  redirectTo: string;
}

const RequireAuth = ({ children, redirectTo }: IProps) => {
  const { isLoading, isAuthenticated } = useAuth0();
  if (isLoading) {
    return <Loader />
  }

  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

export default RequireAuth;
