export const generateCode = () => {
  const alphaNum =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const idLen = 32;
  let id = '';
  for (let i = 0; i < idLen; i += 1) {
    if (i % 8 === 0 && i !== 0) {
      id += '-';
    }
    id += alphaNum.charAt(Math.floor(Math.random() * alphaNum.length));
  }
  return id;
};

export const getWeek = (offset: number, days: number = 7) => {
  let week = []
  let today = new Date()
  let firstday = today.getDate() - Math.floor(days/2) + (offset * days)

  for (let i = firstday; i < firstday + days; i += 1) {
    let curr = new Date()
    week.push(new Date(curr.setDate(i)))
  }

  return week
}

export const getMonthStr = (dates: Date[]) => {
  var monthsStr: string[] = []
  var yearsStr: string[] = []
  var moment = require('moment')
  moment.updateLocale('es', {
    months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
    monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
  })
  //moment.locale('es')

  for (let date of dates) {
    let tmpM = moment(date).format('MMMM')
    let tmpY = moment(date).format('YYYY')
    if (! monthsStr.includes(tmpM)) {
      monthsStr.push(tmpM)
    }
    if (! yearsStr.includes(tmpY)) {
      yearsStr.push(tmpY)
    }
  }

  return monthsStr.join(" - ") + " " + yearsStr.join(" - ")
}
