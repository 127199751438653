import {
  colors,
  responsiveContentPadding,
  spacing,
  startWith,
  typography,
} from 'common/theme/styles';

import styled from 'styled-components';

export const StyledPageTitle = styled.div`
  padding: ${spacing.tight} ${spacing.regular};
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 2px solid ${colors.border};
  flex-wrap: wrap;
  ${responsiveContentPadding}

  &.hasTabNavigation {
    padding-bottom: 0;
  }

  > div:first-of-type {
    > .backlink {
      font-size: ${typography.sizeF1};
      font-weight: ${typography.weightLightMedium};
      display: flex;
      align-items: center;
      color: ${colors.main};
      text-decoration: none;

      > svg {
        display: block;
        margin-right: ${spacing.xs};
      }

      > span {
        position: relative;
        display: block;
      }
    }

    > .title {
      font-size: ${typography.sizeF4};
      font-weight: ${typography.weightMedium};
      display: block;

      @media screen and (min-width: 1000px) {
        font-size: ${typography.sizeF5};
      }
    }
  }

  > div.tabActions {
    display: flex;
    align-items: center;
    margin-top: ${spacing.tight};

    ${startWith('md')} {
      margin-top: 0;
    }

    > button:not(:first-child),
    > a:not(:first-child) {
      margin-left: 5px;
    }
  }

  > div.tabNavigation {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 10px;
    flex-direction: column-reverse;

    @media screen and (min-width: 1000px) {
      align-items: flex-end;
      flex-direction: row;
    }

    > div:first-of-type {
      display: flex;
      align-items: center;

      > a {
        display: block;
        margin-right: 32px;
        font-weight: 600;
        font-size: 14px;
        color: ${colors.grey};
        text-decoration: none;
        border-bottom: 2px solid transparent;
        padding-bottom: 10px;
        transform: translateY(2px);
        transition: color 200ms ease-out, border-bottom-color 200ms ease-out;

        &.active,
        &:hover {
          color: ${colors.main};
          border-bottom-color: ${colors.main};
        }
      }
    }
  }
`;
