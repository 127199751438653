import './App.less';
import React from 'react';
import AppRoutes from 'router/AppRoutes';

class App extends React.Component<any, any> {
  public render() {
    return (
      <>
        <AppRoutes />
      </>
    );
  }
}

export default App;
