import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router'
import Loader from 'uiComponents/Loader';
import { useEffect } from 'react';

export const Callback = () => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/')
    }
  }, [isAuthenticated, navigate]);

  return <Loader />
};
